import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoute, PublicRoute } from '@skillset/onelogin';
import { LoadingScreen } from '@skillset/ui';
import { Administration } from '../pages/Administration/Administration';
import { Jobs } from '../pages/Jobs/Jobs';
import { RoleProtectedRoute } from '../components/RoleProtectedRoute/RoleProtectedRoute';
import { Home } from '../components/Home/Home';
import { ProtectedRouteByPlan } from '../pages/ProtectedRouteByPlan/ProtectedRouteByPlan';
import { zIndices } from '../assets/zIndices';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { SwitchDevice } from '../pages/SwitchDevice/SwitchDevice';
import RenderApplyToBusinessJobs from '../components/RenderApplyToBusinessJobs/RenderApplyToBusinessJobs';

const AllCustomers = lazy(() => import(/* webpackChunkName: "customers" */ '../pages/Candidates/AllCustomers'));

// import { Candidates } from '../pages/Candidates/Candidates';
// const OldCandidates = lazy(() => import(/* webpackChunkName: "oldcandidates" */pages/OldCandidates/Candidates'));
const SuperAdministrator = lazy(
  () => import(/* webpackChunkName: "customers" */ '../pages/SuperAdministrator/SuperAdministrator'),
);

const FavoriteCandidatesGateway = lazy(
  () => import(/* webpackChunkName: "favorite-candidates" */ '../pages/Candidates/FavoriteCandidatesGateway'),
);
const CandidatesBySimulationType = lazy(
  () =>
    import(/* webpackChunkName: "candidates-per-simulation-type" */ '../pages/Candidates/CandidatesBySimulationType'),
);
const CandidatesByJob = lazy(
  () => import(/* webpackChunkName: "candidates-per-job" */ '../pages/Candidates/CandidatesByJob'),
);

const ReportRenderer = lazy(() => import(/* webpackChunkName: "report" */ '../pages/ReportRenderer/ReportRenderer'));
const Settings = lazy(() => import(/* webpackChunkName: "settings" */ '../pages/Settings/Settings'));

const Login = lazy(() => import(/* webpackChunkName: "login-page" */ '../pages/SourcingLogin/SourcingLogin'));
const RecoverAccount = lazy(
  () => import(/* webpackChunkName: "recover-account" */ '../pages/SourcingLogin/SourcingRecoverAccount'),
);
const SignUp = lazy(() => import(/* webpackChunkName: "sign-up" */ '../pages/SourcingLogin/SourcingSignUp'));
const SourcingLoginAfterGoogle = lazy(
  () => import(/* webpackChunkName: "sign-in-with-google" */ '../pages/SourcingLogin/SourcingLoginAfterGoogle'),
);
const TermsOfUse = lazy(
  () => import(/* webpackChunkName: "terms-of-use" */ '../pages/SourcingLogin/SourcingTermsOfUse'),
);

const CATCH_ALL_PAGE = <Navigate replace to="/login" />;

function addDefaultProps<T extends object>(
  C: React.FC<T>,
  props: React.FC<T> extends React.FC<infer Props> ? Partial<Props> : never,
) {
  C.defaultProps = { ...(C.defaultProps ?? {}), ...props };
}

function addFallback(Cmp: typeof PublicRoute | typeof ProtectedRoute) {
  addDefaultProps(Cmp, { fallback: <LoadingScreen zindex={zIndices.loadingBackDrop} /> });
}
addFallback(PublicRoute);
addFallback(ProtectedRoute);

export const AppRoutes: React.FC = (): JSX.Element => {
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });

  addDefaultProps(PublicRoute, { optionalAuthenticatedPath: isMobile ? '/switch-device' : '/home' });

  const authRoutes = (
    <>
      {/* //Auth Routes */}
      {/* Please notice: SignUp is not a PublicRoute to avoid infinite redirections in a flow where user authenticated and didn't fill questionnaire yet. */}

      <Route
        path="accounts/signup/*"
        element={
          <Suspense fallback={null}>
            <SignUp />
          </Suspense>
        }
      />
      <Route path="accounts/recover/*" element={<PublicRoute Component={RecoverAccount} />} />
      <Route path="/login" element={<PublicRoute Component={Login} />} />
      <Route path="/social-login" element={<PublicRoute Component={SourcingLoginAfterGoogle} />} />
      <Route
        path="terms-of-use"
        element={
          <Suspense fallback={null}>
            <TermsOfUse />
          </Suspense>
        }
      />
      {/* End of Auth Routes */}
    </>
  );
  const appRoutes = (
    <>
      <Route path="/home" element={<ProtectedRoute Component={Home} />} />
      <Route
        path="/jobs"
        element={
          <ProtectedRouteByPlan
            forbiddenMainBusinessPage="/candidates/simulation"
            element={<ProtectedRoute Component={Jobs} />}
          />
        }
      />
      <Route path="/candidates/favorites" element={<ProtectedRoute Component={FavoriteCandidatesGateway} />} />
      <Route
        path="/candidates/favorites/customer/:customerId/"
        element={<ProtectedRoute Component={FavoriteCandidatesGateway} />}
      />
      <Route
        path="/candidates/favorites/customer/:customerId/simulation/:simTypeId"
        element={<ProtectedRoute Component={FavoriteCandidatesGateway} />}
      />
      <Route
        path="/candidates/job/:jobId"
        element={
          <ProtectedRouteByPlan
            forbiddenMainBusinessPage="/candidates/simulation"
            element={<ProtectedRoute Component={CandidatesByJob} />}
          />
        }
      />
      <Route
        path="/customers"
        element={
          <ProtectedRouteByPlan
            forbiddenMainBusinessPage="/jobs"
            element={<ProtectedRoute Component={AllCustomers} />}
          />
        }
      />
      <Route
        path="/super-admin"
        element={
          <ProtectedRouteByPlan
            forbiddenMainBusinessPage="/jobs"
            element={<ProtectedRoute Component={SuperAdministrator} />}
          />
        }
      />
      {/* <Route path="/candidates/simulation/favorites" element={<ProtectedRoute Component={Candidates} />} /> */}
      <Route
        path="/candidates/simulation"
        element={
          <ProtectedRouteByPlan
            forbiddenMainBusinessPage="/jobs"
            element={<ProtectedRoute Component={CandidatesBySimulationType} />}
          />
        }
      />
      <Route
        path="/candidates/customer/:customerId/simulation"
        element={
          <ProtectedRouteByPlan
            forbiddenMainBusinessPage="/jobs"
            element={<ProtectedRoute Component={CandidatesBySimulationType} />}
          />
        }
      />
      <Route
        path="/candidates/customer/:customerId/simulation/:simTypeId"
        element={
          <ProtectedRouteByPlan
            forbiddenMainBusinessPage="/jobs"
            element={<ProtectedRoute Component={CandidatesBySimulationType} />}
          />
        }
      />
      {/* <Route path="/candidates/simulations/:simTypeId" element={<ProtectedRoute Component={Candidates} />} /> */}
      <Route
        path="/administration"
        element={
          <RoleProtectedRoute element={<ProtectedRoute Component={Administration} />} roles={['admin', 'superAdmin']} />
        }
      />
      <Route
        path="/administration/settings"
        element={
          <RoleProtectedRoute element={<ProtectedRoute Component={Settings} />} roles={['admin', 'superAdmin']} />
        }
      />
      <Route
        path="/administration/apply-to-business-preview/:uniqueLink"
        element={
          <RoleProtectedRoute
            element={<ProtectedRoute Component={RenderApplyToBusinessJobs} />}
            roles={['admin', 'superAdmin']}
          />
        }
      />
      <Route path="candidates/report/:sessionId" element={<ProtectedRoute Component={ReportRenderer} />} />
    </>
  );

  return (
    <Routes>
      {!isMobile && appRoutes}
      {authRoutes}
      {isMobile && <Route path="/switch-device" element={<ProtectedRoute Component={SwitchDevice} />} />}
      //! must be last route to work properly
      <Route path="*" element={CATCH_ALL_PAGE} />
    </Routes>
  );
};
