import { forwardRef } from 'react';
import { FloatingFocusManager, FloatingPortal, Placement } from '@floating-ui/react';

import { MenuListProvider } from './MenuListProvider';
import { useFloatingMenu } from './useFloatingMenu';

import { MenuWrapper } from './style';

export interface MenuProps {
  label: string;
  theme?: 'light' | 'dark';
  nested?: boolean;
  zIndex?: number;
  children?: React.ReactNode;
  placement?: Placement;
  buttonChildren?: React.ReactNode;
  offset?: {
    x?: number;
    y?: number;
  };
  onOpenChange?: (open: boolean) => void;
}

export const MenuComponent = forwardRef<HTMLButtonElement, MenuProps & React.HTMLProps<HTMLButtonElement>>(
  (props, forwardedRef) => {
    const { buttonChildren, children, label, theme = 'light', ...restOfProps } = props;
    const {
      isNested,
      isOpen,
      MenuAndButtonWrapper,
      rootButtonProps,
      focusManagerProps,
      menuWrapperProps,
      menuListProviderProps,
    } = useFloatingMenu(restOfProps, forwardedRef);

    return (
      <MenuAndButtonWrapper>
        <button {...rootButtonProps}>
          {buttonChildren ?? label}
          {isNested && (
            <span aria-hidden style={{ marginLeft: 10, fontSize: 10 }}>
              ▶
            </span>
          )}
        </button>
        <MenuListProvider {...menuListProviderProps}>
          {isOpen && (
            <FloatingPortal>
              <FloatingFocusManager {...focusManagerProps}>
                <MenuWrapper {...menuWrapperProps} className={`Menu ${theme}`}>
                  {children}
                </MenuWrapper>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </MenuListProvider>
      </MenuAndButtonWrapper>
    );
  },
);
MenuComponent.displayName = 'MenuComponent.forwardRef';
