import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { storageKeys } from '../../constants';
import { IPlatformUser } from '../../utils/types';
import { amplifyErrorHandler } from '../../utils/errorHandlers';
import { StorageService } from '../../services/storage.service';
import { CognitoUser, userService } from '../../services/user.service';
import { NavigateFunction } from 'react-router-dom';
import { Language, DefaultLanguage } from '../../utils/languages/general';

const AnonymousLogin = async (email: string) => {
  const signInUser = await Auth.signIn(email);
  if (signInUser.challengeName === 'CUSTOM_CHALLENGE') {
    // Respond to the custom challenge
    await Auth.sendCustomChallengeAnswer(signInUser, 'bypass');
  }
};

const login = async (password: string | undefined, email: string, userIp: string) => {
  if (password) {
    await Auth.signIn(email.toLowerCase(), password, {
      source_ip: userIp, // Add the IP address to clientMetadata
    });
  } else {
    await AnonymousLogin(email);
  }
};

const logout = async () => {
  await Auth.signOut();
  const lang = StorageService.get('lang');
  localStorage.clear();
  localStorage.removeItem('desiredUrl');
  localStorage.removeItem('referrer');
  StorageService.set('lang', lang);
};

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (
    credentials: {
      email: string;
      password: string;
      search: string;
      platformName: string;
      language?: Language;
    },
    thunkAPI,
  ) => {
    const { email, password, search, language } = credentials;
    let utms = search.split('?')[1] || '';
    if (utms != '') utms = '?' + utms;
    const query = new URLSearchParams(utms);
    try {
      const uniqueLink = search.split('?')[0].replace('/', '');
      await Auth.signUp({
        username: email.toLowerCase(),
        password,
        autoSignIn: {
          enabled: true,
        },
        attributes: {
          'custom:utm_source': query.get('utm_source'),
          'custom:utm_medium': query.get('utm_medium'),
          'custom:utm_campaign': query.get('utm_campaign'),
          'custom:unique_link': uniqueLink.length === 8 ? uniqueLink : 'nouniqlk',
          'custom:reg_lang': language ?? DefaultLanguage,
        },
      });
    } catch (error) {
      if (typeof error === 'string') {
        return thunkAPI.rejectWithValue(error);
      } else if (error instanceof Error) {
        const errorToSet = amplifyErrorHandler(error);
        if (!errorToSet) {
          console.log('**UNHANDLED ERROR**:', error.name, error.message);
        }
        return thunkAPI.rejectWithValue(errorToSet);
      }
    }
  },
);

export const confirmSignUp = createAsyncThunk(
  'auth/confirmSignUp',
  async (credentials: { username: string; code: string; platformName: string }, thunkAPI) => {
    const { username, code } = credentials;
    try {
      Auth.confirmSignUp(username.toLowerCase(), code);
    } catch (error) {
      if (typeof error === 'string') {
        return thunkAPI.rejectWithValue(error);
      } else if (error instanceof Error) {
        const errorToSet = amplifyErrorHandler(error);
        if (!errorToSet) {
          console.log('**UNHANDLED ERROR**:', error.name, 'message', error.message);
        }
        return thunkAPI.rejectWithValue(errorToSet);
      }
    }
  },
);

export const resendSignupCode = createAsyncThunk('auth/resendSignupCode', async (email: string, thunkAPI) => {
  try {
    await Auth.resendSignUp(email);
  } catch (error) {
    if (typeof error === 'string') {
      return thunkAPI.rejectWithValue(error);
    } else if (error instanceof Error) {
      const errorToSet = amplifyErrorHandler(error);
      if (!errorToSet) {
        console.log('**UNHANDLED ERROR**:', error.name, 'message', error.message);
      }
      return thunkAPI.rejectWithValue(errorToSet);
    }
  }
});

export const anonymousSignUp = createAsyncThunk(
  'auth/anonymousSignUp',
  async (
    parameters: {
      email: string;
      uniqueLink: string;
    },
    thunkAPI,
  ) => {
    try {
      const { email, uniqueLink } = parameters;

      let newPlatformUser = null;
      newPlatformUser = await userService.createAnonymous(email, uniqueLink);
      StorageService.set(storageKeys.platformUser, newPlatformUser);

      return {
        cognitoUser: '',
        platformUser: newPlatformUser,
      };
    } catch (error) {
      if (typeof error === 'string') {
        return thunkAPI.rejectWithValue(error);
      } else if (error instanceof Error) {
        const errorToSet = amplifyErrorHandler(error);
        if (!errorToSet) {
          console.log('**UNHANDLED ERROR**:', error.name, error.message);
        }
        return thunkAPI.rejectWithValue(errorToSet);
      }
    }
  },
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (
    {
      ip = '',
      email,
      password,
      platformName,
      desiredUrl,
    }: { ip: string; email: string; password?: string; platformName: string; desiredUrl?: string },
    thunkAPI,
  ) => {
    let lang = undefined;
    try {
      const language = desiredUrl?.split('?')[1];
      lang = language && language.indexOf('lang=') !== -1 ? (language.replace('lang=', '') as Language) : undefined;
      await login(password, email, ip);
      const cognitoUser = await Auth.currentUserInfo();

      // // // Incase groups role mapping failed Amplify won't return id on the cognitoUser. *Very Important to not remove that note*
      if (!cognitoUser.id) return thunkAPI.rejectWithValue("This account isn't authorized to access this platform.");

      const platformUser = await userService.get(platformName);
      if (platformUser) await userService.updateLastVisit(desiredUrl);
      StorageService.set(storageKeys.platformUser, platformUser.user);
      return {
        cognitoUser,
        platformUser: platformUser.user,
      };
    } catch (error) {
      if (typeof error === 'string') {
        return thunkAPI.rejectWithValue(error);
      } else if (error instanceof Error) {
        const errorToSet = amplifyErrorHandler(error, lang);
        if (!errorToSet) {
          console.log('**UNHANDLED ERROR**:', error.name, error.message);
        }
        return thunkAPI.rejectWithValue(errorToSet);
      }
    }
  },
);

export const signInWithGoogle = createAsyncThunk(
  'auth/signInWithGoogle',
  async (
    {
      navigate,
      successRoute,
      errorRoute,
      platformName,
    }: {
      navigate: NavigateFunction;
      successRoute: string;
      errorRoute: string;
      platformName: string;
    },
    thunkAPI,
  ) => {
    try {
      try {
        const cognitoUser = await Auth.currentUserInfo();
        // Incase groups role mapping failed Amplify won't return id on the cognitoUser. *Very Important to not remove that note*
        if (!cognitoUser.id) return thunkAPI.rejectWithValue("This account isn't authorized to access this platform.");
        if (successRoute !== 'home') await userService.updateLastVisit(successRoute);
        const platformUser = await userService.get(platformName);
        StorageService.set(storageKeys.platformUser, platformUser.user);
        navigate(successRoute);
        return {
          cognitoUser,
          platformUser: platformUser.user,
        };
      } catch (e) {
        throw e;
      }
    } catch (error) {
      navigate(errorRoute);
      if (typeof error === 'string') {
        return thunkAPI.rejectWithValue(error);
      } else if (error instanceof Error) {
        const errorToSet = amplifyErrorHandler(error);
        if (!errorToSet) {
          console.log('**UNHANDLED ERROR**:', error.name, error.message);
        }
        return thunkAPI.rejectWithValue(errorToSet);
      }
    }
  },
);

export const signOut = createAsyncThunk('auth/signOut', async () => {
  try {
    await logout();
  } catch (error) {
    // return thunkAPI.rejectWithValue(error.response.data.name);
  }
});

export const getAuthenticatedUser = createAsyncThunk(
  'auth/getAuthenticatedUser',
  async (
    {
      platformName,
      referrer,
      isWarehouseReferrer,
    }: { platformName: string; referrer?: string; isWarehouseReferrer?: boolean },
    thunkAPI,
  ) => {
    try {
      const getCognitoUser = async (): Promise<CognitoUser | null> => {
        const user = await Auth.currentUserInfo();
        return user ?? (await Auth.currentAuthenticatedUser());
      };

      const logoutClearLocalStorageAndReject = async () => {
        await logout();
        return thunkAPI.rejectWithValue('');
      };

      const cognitoUser = await getCognitoUser();
      if (!cognitoUser) return thunkAPI.rejectWithValue('');
      if ((referrer && !isWarehouseReferrer) || !referrer) {
        const { attributes } = cognitoUser;
        const isAnonymous = attributes?.['custom:isAnonymous'];
        const anonymousUserName = new URLSearchParams(window.location.search).get('username');

        if (!isWarehouseReferrer && (isAnonymous === '1' || anonymousUserName)) {
          return await logoutClearLocalStorageAndReject();
        }
      }

      let platformUser = StorageService.get(storageKeys.platformUser) as IPlatformUser | null;
      if (!platformUser) {
        const data = await userService.get(platformName);
        platformUser = data?.user;
        if (platformUser) StorageService.set(storageKeys.platformUser, platformUser);
      }
      return {
        cognitoUser: JSON.parse(JSON.stringify(cognitoUser)),
        platformUser,
      };
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const saveUserData = createAsyncThunk(
  'auth/saveUserData',
  async (
    {
      userToSave,
      platformName,
      uniqueLink,
    }: {
      userToSave: Omit<IPlatformUser, 'businessId'>;
      platformName: string;
      uniqueLink?: string;
    },
    thunkAPI,
  ) => {
    try {
      const res: IPlatformUser | null = await userService.create(userToSave, platformName, uniqueLink);
      // Trigger signup last step (questionnaire phase) SEO event

      return res;
    } catch (error) {
      console.log('err from saveUserData', error);
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const requestForgotPassword = createAsyncThunk(
  'auth/requestForgotPassword',
  async ({ email, language }: { email: string; language?: Language }, thunkAPI) => {
    try {
      const lang: string = language ?? 'he';
      console.log('lang : ', lang);
      await Auth.forgotPassword(email, {
        'custom:reg_lang': lang,
      });
    } catch (error) {
      if (typeof error === 'string') {
        return thunkAPI.rejectWithValue(error);
      } else if (error instanceof Error) {
        const errorToSet = amplifyErrorHandler(error);
        if (!errorToSet) {
          console.log('**UNHANDLED ERROR**:', error.name, 'message', error.message);
          return thunkAPI.rejectWithValue(error.message);
        }
        return thunkAPI.rejectWithValue(null);
      }
    }
  },
);

export const completeForgotPassword = createAsyncThunk(
  'auth/completeForgotPassword',
  async (params: { username: string; code: string; password: string }, thunkAPI) => {
    const { username, code, password } = params;
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
    } catch (error) {
      if (typeof error === 'string') {
        return thunkAPI.rejectWithValue(error);
      } else if (error instanceof Error) {
        const errorToSet = amplifyErrorHandler(error);
        if (!errorToSet) {
          console.log('**UNHANDLED ERROR**:', error.name, 'message', error.message);
          return thunkAPI.rejectWithValue(error.message);
        }
        return thunkAPI.rejectWithValue(null);
      }
    }
  },
);
