import { IAuthSlice } from '@skillset/onelogin';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LoadingScreen, SideMenu } from '@skillset/ui';
import { PageWrapper } from './components/PageWrapper/PageWrapper';
import { sideMenuStartsWithRoutes } from './constants/routes';
import { AppRoutes } from './routes';
import { RootState, useAppDispatch, useTypedSelector } from './store/store';

import { useWindowDimensions } from './hooks/useWindowDimensions';
import { useEffect } from 'react';
import { getBusinessPermissions } from './store/actions/employerBusinessSlice.action';
import { zIndices } from '../src/assets/zIndices';
import { useIsSufficientRole } from './components/RoleProtectedRoute/RoleProtectedRoute';
import { selectCustomers, selectMainBusinessPage, userBelongsToBusinessSelector } from './store/selectors';

const useIsAdministration = () => {
  const location = useLocation();
  const { isSufficientRole } = useIsSufficientRole(['admin', 'superAdmin']);
  const routeName = location.pathname.replace('/', '');
  const isAdministration = routeName.startsWith('administration');
  return isAdministration && isSufficientRole;
};

const useIsSuperAdministration = () => {
  const location = useLocation();
  const { isSufficientRole: isSuperAdmin } = useIsSufficientRole(['superAdmin']);
  const routeName = location.pathname.replace('/', '');
  const isAdministration = routeName.startsWith('administration');
  return isAdministration && isSuperAdmin;
};

const RenderSideMenu = () => {
  const userBelongsToBusiness = useTypedSelector(userBelongsToBusinessSelector);
  const mainBusinessPage = useTypedSelector(selectMainBusinessPage);
  const isAdministration = useIsAdministration();
  const isSuperAdministration = useIsSuperAdministration();

  const customers = useSelector(selectCustomers);
  const hasAtsIntegration = mainBusinessPage === '/jobs';
  return (
    <SideMenu
      hasAtsIntegration={hasAtsIntegration}
      isAdministration={isAdministration}
      userBelongsToBusiness={userBelongsToBusiness}
      zIndexBackdropLayer={zIndices.backdrop}
      zIndexMobileRouteListWrapper={zIndices.sideMenuMobileRouteList}
      zIndexTooltip={zIndices.sideMenuTooltip}
      noCustomersBussiness={customers.length === 1}
      isSuperAdmin={isSuperAdministration}
    />
  );
};

function App() {
  const location = useLocation();
  const authSlice = useSelector<RootState, IAuthSlice>((state) => state.authSliceReducer);
  const dispatch = useAppDispatch();

  const { loading: authLoading, authenticated, platformUser } = authSlice;
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });

  const shouldRenderSideMenu =
    authenticated && sideMenuStartsWithRoutes.some((route) => location.pathname.startsWith(route)) && !isMobile;

  useEffect(() => {
    if (platformUser) {
      dispatch(getBusinessPermissions());
    }
  }, [platformUser]);

  return (
    <div className="App">
      {shouldRenderSideMenu && <RenderSideMenu />}
      {authLoading && <LoadingScreen zindex={zIndices.loadingBackDrop} />}
      <PageWrapper>
        <AppRoutes />
      </PageWrapper>
    </div>
  );
}

export default App;
