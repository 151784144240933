import { InputElement, SearchIcon, SearchWrapper } from './style';

interface ISearchBar {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const SearchBar: React.FC<ISearchBar> = ({ value, onChange }) => {
  const handleInputClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
  };
  return (
    <SearchWrapper>
      <InputElement
        type="search"
        placeholder="Search"
        value={value}
        onChange={onChange}
        onClick={handleInputClick}
        onKeyDown={handleKeyDown}
      />
      <SearchIcon aria-hidden="true" />
    </SearchWrapper>
  );
};
