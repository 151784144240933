import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useInput } from '../../hooks/useInput';

import { RECOVER_ROUTES, SIGN_UP_ROUTES } from '../../constants/routes';
import { isNotEmpty, valueIsMatching } from '../../utils/validations';
import { EMAIL_REGEX } from '../../constants/regularExpressions';
import { Checkbox } from '@skillset/ui';
import { FormInputGroup } from '../FormInputGroup/FormInputGroup';
import { PasswordInput } from '../FormInputGroup/PasswordInput';

import { ButtonOrangeFilled } from '../Reusable-styled-elements/Button';
import { ButtonContainer } from '../RegisterForm/style';
import { ForgotPasswordLink, RememberMeContainer, RememberMeText } from './style';

import { signIn } from '../../store/actions/auth.action';
import { RootState, useAppDispatch } from '../../store/store';
import { IAuthSlice } from '../../store/slices/authSlice';
import { useSelector } from 'react-redux';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { GoogleLoginForm } from '../GoogleLoginForm/GoogleLoginForm';
import { Language } from '../../utils/languages/general';
import { getLoginTexts } from '../../services/languages.service';

export const LoginForm: React.FC<{ platformName: string; desiredUrl?: string; registrationLanguage?: Language }> = ({
  platformName,
  desiredUrl,
  registrationLanguage,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error } = useSelector<RootState, IAuthSlice>((state) => state.authSliceReducer);
  const texts = getLoginTexts(registrationLanguage);
  const {
    value: email,
    hasError: emailHasError,
    valueIsValid: emailIsValid,
    ...restEmailInputProps
  } = useInput({
    defaultValue: '',
    validate: (value) => isNotEmpty(value) && valueIsMatching(value, EMAIL_REGEX),
  });

  const {
    value: password,
    hasError: passwordHasError,
    valueIsValid: passwordIsValid,
    ...restPasswordInputProps
  } = useInput({
    defaultValue: '',
    validate: (value) => isNotEmpty(value),
  });

  const [rememberMeChecked, setRememberMeChecked] = useState<boolean>(false);

  const handleRememberMeChange = () => setRememberMeChecked((prevState) => !prevState);
  async function getUserIp() {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip as string; // Returns the user's public IP
  }
  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!emailIsValid || !passwordIsValid) {
      restEmailInputProps.onBlur();
      restPasswordInputProps.onBlur();
      return;
    }
    const ip = platformName === 'Sourcing' ? await getUserIp() : '';
    const result = await dispatch(signIn({ ip, email, password, platformName, desiredUrl }));
    if (result?.payload === 'UserNotConfirmedException') {
      navigate(SIGN_UP_ROUTES.CONFIRM, { state: { email, shouldResendCode: true } });
    }
  };

  return (
    <form onSubmit={submitHandler} style={{ marginTop: '15px' }}>
      <FormInputGroup
        type="email"
        label={texts.email}
        required
        errorMessage={emailHasError ? texts.validEmailError : ''}
        isValid={emailIsValid}
        placeholder="email@domain.com"
        value={email}
        id="email-input"
        hideStatusIndications={true}
        {...restEmailInputProps}
      />
      <PasswordInput
        label={texts.password}
        required
        errorMessage={passwordHasError ? texts.validPasswordError : ''}
        isValid={passwordIsValid}
        name="password"
        id="password-input"
        value={password}
        {...restPasswordInputProps}
      />
      {error && <ErrorMessage error={error} />}
      <RememberMeContainer>
        <Checkbox checked={rememberMeChecked} onChange={handleRememberMeChange}>
          <RememberMeText>{texts.rememberMe}</RememberMeText>
        </Checkbox>
        <ForgotPasswordLink to={RECOVER_ROUTES.FORGOT_PASSWORD}>{texts.forgotPassword}</ForgotPasswordLink>
      </RememberMeContainer>
      <ButtonContainer>
        <ButtonOrangeFilled type="submit" id="submit-login">
          {texts.loginToYourAccount}
        </ButtonOrangeFilled>
        <GoogleLoginForm shouldDisableRegistration={false} registrationLanguage={registrationLanguage} />
      </ButtonContainer>
    </form>
  );
};
