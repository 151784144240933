import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { LoadingScreen } from '@skillset/ui';

import { RootState, useAppDispatch, useTypedSelector } from '../../store/store';
import { selectLoadingBusinessSlice, selectMainBusinessPage } from '../../store/selectors';
import { MainBusinessPages } from '../../store/types/EmployerBusinessSliceInterface';

import { zIndices } from '../../assets/zIndices';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { selectPlatformUser } from '@skillset/onelogin';
import { useSelector } from 'react-redux';
import { EmployerSuperAdministration } from '../../store/types/EmployerSuperAdministrationInterface';
import { getBussinesses, setCurrentBussiness } from '../../store/actions/employerSuperAdministrator.action';

export const Home: React.FC = () => {
  const { isAtRequestedWidth: isMobile } = useWindowDimensions({ requestedWidth: 767 });

  const mainBusinessPage = useTypedSelector(selectMainBusinessPage);
  const loading = useTypedSelector(selectLoadingBusinessSlice);
  const platformUser = useTypedSelector(selectPlatformUser);

  const isSuperAdmin = platformUser?.isSuperAdmin;
  const superAdminstrationSlice = useSelector<RootState, EmployerSuperAdministration>(
    (state) => state.employersSuperAdministrationSliceReducer,
  );
  const { bussinesses, currentBussiness } = superAdminstrationSlice;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isSuperAdmin && (!bussinesses || bussinesses.length === 0)) {
      dispatch(getBussinesses());
      dispatch(setCurrentBussiness());
    }
  }, []);

  if (isMobile) {
    return <Navigate replace to="switch-device" />;
  }
  if (mainBusinessPage && !loading) {
    return (
      <Navigate replace to={isSuperAdmin && !currentBussiness ? MainBusinessPages.superadmin : mainBusinessPage} />
    );
  } else if (loading) {
    return <LoadingScreen zindex={zIndices.loadingBackDrop} />;
  }
  return <Navigate replace to={MainBusinessPages.jobs} />;
};
